import React from "react";
import { Component } from "react";
import olMap from "../../map";
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import pointsrc from "../icons/point.svg"
import { Style, Circle as CircleStyle, Fill, Icon } from 'ol/style'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
// import { click } from 'ol/events/condition.js';
// import Select from 'ol/interaction/Select';
/**
 * @作者:hyh
 * @组件说明:在地图上添加点位
 * @组件:CPoint
 * @param:
        LonLat           //添加的点位经纬度  默认莲都区
        key              // 点位图层的名称
        color            //点位颜色     默认红色
        radius           //点位大小  默认20
        type             //点位类型    point | icon
        src              // icon 图标  存在默认图标
        imgSize          //图标大小  默认[50,50]
        offset           //图标偏移量  默认 [0,0]
        scale            //图标缩放  默认 1
        data             //点位数据  格式[[],[],[],[],[]]
        zIndex           //点位图层层级    
        onClick          //点位点击事件
 */
interface CPointprops {
    LonLat?: number[] //点位经纬度
    key?: any
    color?: string     //点位颜色
    radius?: number    //点位大小
    type?: string      //点位类型 默认point  可选 icon
    src?: string       // icon 图标  存在默认图标
    imgSize?: number[]  //图标大小
    offset?: number[]   //图标偏移量
    scale?: number      //图标缩放
    data?: any           //点位数据
    zIndex?: number      //点位层级
    onClick?: React.MouseEventHandler<HTMLElement>
}

export default class CPoint extends Component<CPointprops>{

    componentDidMount = () => {
        const { LonLat, color, radius, type, src, imgSize, offset, scale, zIndex, data, key } = this.props

        if (data) {
            this.addMorePoint({ data, color, radius, type, src, imgSize, offset, scale, key, zIndex })
        } else {
            this.addPoint({ LonLat, color, radius, type, src, imgSize, offset, scale, zIndex })
        }
        olMap.map.on("singleclick", this.pointClick)

        // let clickSelect = new Select({
        //     condition: click,
        //     hitTolerance: 5,
        //     style: new Style({
        //         image: new CircleStyle({
        //             fill: new Fill({
        //                 color: 'red',

        //             }),
        //             stroke: new Stroke({
        //                 color: '#4C99F8',
        //                 width: 3,
        //             }),
        //             radius: 20,
        //         }),
        //     })
        // });
        // clickSelect.on('select', onClick)
        // olMap.map.addInteraction(clickSelect);
    }


    componentWillUnmount = () => {
        olMap.map.un("singleclick", this.pointClick)
    }

    //点位点击事件
    pointClick = (events: any) => {
        const { onClick } = this.props
        const feature = olMap.map.forEachFeatureAtPixel(events.pixel, (feature: any) => {
            return feature;
        });
        if (feature && feature.values_.type === "point") {
            if (onClick) {
                return onClick(feature)
            }
        }
    }


    //添加点位
    addPoint = ({ LonLat, color, radius, type, src, imgSize, offset, scale, zIndex }: pointProps) => {
        // 实例化要素
        let feature = new Feature({
            type: "point",
            geometry: new Point(LonLat ? LonLat : [119.9133, 28.4233])
        })
        if (type === "icon") {
            feature.setStyle([
                new Style({
                    image: new Icon({
                        src: src ? src : pointsrc,
                        imgSize: imgSize ? imgSize : [50, 50],
                        offset: offset ? offset : [0, 0],
                        scale: scale ? scale : 1,
                    })
                })
            ])
        } else {
            feature.setStyle([
                new Style({
                    image: new CircleStyle({
                        fill: new Fill({
                            color: color ? color : 'red',

                        }),
                        radius: radius ? radius : 7,
                    }),
                })
            ])
        }
        olMap.layers.singlePointLayer.setZIndex(zIndex ? zIndex : 1)
        olMap.layers.singlePointLayer.getSource()?.addFeature(feature)
    }

    //添加多个点位
    addMorePoint = ({ data, color, radius, type, src, imgSize, offset, scale, key, zIndex }: pointProps) => {
        const newdata = data.map((item: any) => {
            return new Feature({
                type: "point",
                geometry: new Point(item),
            })
        })
        if (type === "icon") {
            newdata.map((item: any) => {
                item.setStyle(new Style({
                    image: new Icon({
                        src: src,
                        imgSize: imgSize ? imgSize : [50, 50],
                        offset: offset ? offset : [0, 0],
                        scale: scale ? scale : 1,
                    })
                }))
            })
        } else {
            newdata.map((item: any) => {
                item.setStyle(new Style({
                    image: new CircleStyle({
                        fill: new Fill({
                            color: color ? color : 'red',

                        }),
                        radius: radius ? radius : 8,
                    }),
                }))
            })
        }
        let source = new VectorSource({
            features: newdata
        })
        const pointLayers = new VectorLayer({
            zIndex: zIndex ? zIndex : 1,
            source: source
        })
        pointLayers.set("id", key ? key : "")
        olMap.map.addLayer(pointLayers)
    }

    render() {
        return <></>;
    }
}
