import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Style, Fill, Stroke, Circle } from 'ol/style'
import React, { Component } from "react";
import { GeoJSON } from 'ol/format';
import { openShp } from "shapefile"
import olMap from "../../map";
/**
 * @作者:zl
 * @组件说明:在地图中导入shp文件
 * @组件:CShp
 * @param:
 * url      //shp文件的url
 * fillColorzz?: any  //填充色
 * borderColor?: any  //边界颜色
 * borderWidth?: number  //边界宽度
 * opacity?: number //透明度
 * zIndex?: number  //层级
 * radius?: number  //点的半径
 * id?: any  //图层id
 */

interface CShpProps {
    url: any, // 导入的shp文件地址
    fillColor?: any  //填充色
    borderColor?: any  //边界颜色
    borderWidth?: number  //边界宽度
    opacity?: number //透明度
    zIndex?: number  //层级
    radius?: number  //点的半径
    id?: any  //图层id
}
export default class CShp extends Component<CShpProps> {
    componentDidMount = () => {
        this.createShp(this.props)
    }

    //导入shp文件
    createShp = ({ url, fillColor, borderColor, borderWidth, opacity, zIndex, radius, id }: CShpProps) => {
        openShp(url, { encoding: "utf-8" }).then(r => r.read()
            .then(function log(result): any {
                if (result.done) return;
                var feature = new GeoJSON().readFeature(result.value);
                let ShpLayer = new VectorLayer({
                    zIndex: zIndex ? zIndex : 1,
                    opacity,
                    source: new VectorSource({
                        features: [feature]
                    }),
                    style: new Style({
                        stroke: new Stroke({
                            color: borderColor ? borderColor : '#3399CC',
                            width: borderWidth ? borderWidth : 1.25
                        }),
                        fill: new Fill({
                            color: fillColor ? fillColor : 'rgba(255,255,255,0.4)'

                        }),
                        image: new Circle({
                            fill: new Fill({
                                color: fillColor ? fillColor : 'red',
                            }),
                            radius: radius ? radius : 5,
                        }),
                    })
                });
                id && ShpLayer.set('id', id)
                olMap.map.addLayer(ShpLayer)

                return r.read().then(log);
            }))
            .catch(error => console.error(error.stack));
    }
    render() {
        return <></>
    }
}
