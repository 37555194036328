import { createBox, createRegularPolygon } from 'ol/interaction/Draw'
import { Draw } from "ol/interaction"
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Style, Fill, Stroke, Circle } from 'ol/style'
import olMap from "../../map";

export class CDraw {
    draw: any;
    layer: any;
    constructor(params: any) {
        const {
            id,
            type = 'Circle',
            style
        } = params
        const {
            fillColor = '#FFFFFF',
            borderColor = '#000000',
            width = 2,
            size = 5,
            color = '#E20000'
        } = style || {}
        const drawType: any = {
            'Point': {
                type: 'Point',
            },
            'Line': {
                type: 'LineString',
            },
            'Polygon': {
                type: 'Polygon',
            },
            'Circle': {
                type: 'Circle',
            },
            'Box': {
                type: 'Circle',
                geometryFunction: createBox()
            },
            'RegularPolygon': {
                type: 'Circle',
                geometryFunction: createRegularPolygon()
            },
        }
        // 指定交互发生的图层
        this.layer = new VectorLayer({
            zIndex: 99,
            source: new VectorSource(),
            style: new Style({
                fill: new Fill({
                    color: fillColor,
                }),
                stroke: new Stroke({
                    color: borderColor,
                    width
                }),
                image: new Circle({
                    radius: size,
                    fill: new Fill({
                        color
                    })
                })
            })
        })
        this.layer.set('id', id)
        this.draw = new Draw({
            source: this.layer.getSource(),
            ...drawType[type],
        })
    }
    // 给交互绑定事件
    on = (key: any, fn: any) => {
        const markerLayer = olMap.getLayer('marker-layer')

        this.draw.on(key, (e: any) => {
            const extent = e.feature.getGeometry().getExtent()
            const param = {
                ...e,
                extent,
                markers: markerLayer.getSource().getFeatures().filter((item: any) => {
                    return item.getGeometry().intersectsExtent(extent)
                })
            }
            fn(param)
        })
    }

    // 给交互移除事件
    un = (key: any, fn: any) => {
        this.draw.un(key, (e: any) => {
            const param = {
                ...e,
                extent: e.feature.getGeometry().getExtent()
            }
            fn(param)
        })
    }

    // 清除绘制的图形
    clear = () => {
        this.layer.getSource().clear()
    }

}