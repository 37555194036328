import React, { Component } from "react";
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import KML from 'ol/format/KML'
import { Style, Fill, Stroke, Circle } from 'ol/style'
import olMap from "../../map";

/**
 * @作者:hyh
 * @组件说明:在地图中导入kml文件
 * @组件:CKML
 * @param:
 * url      //kml文件的url
 * fillColorzz?: any  //填充色
 * borderColor?: any  //边界颜色
 * borderWidth?: number  //边界宽度
 * opacity?: number //透明度
 * zIndex?: number  //层级
 * radius?: number  //点的半径
 * id?: any  //图层id
 */

interface CKMLProps {
    url: any,   //kml文件地址
    fillColorzz?: any  //填充色
    borderColor?: any  //边界颜色
    borderWidth?: number  //边界宽度
    opacity?: number //透明度
    zIndex?: number  //层级
    radius?: number  //点的半径
    id?: any  //图层id
}
export default class CKML extends Component<CKMLProps> {

    componentDidMount = () => {
        this.createKML(this.props)
    }

    createKML = ({ url, fillColor, borderColor, borderWidth, opacity, zIndex, radius, id }: any) => {
        const WFS = new VectorLayer({
            opacity,
            zIndex: zIndex ? zIndex : 1,
            source: new VectorSource({
                url,
                format: new KML({
                    extractStyles: false
                })
            }),
            style: new Style({

                stroke: new Stroke({
                    color: borderColor ? borderColor : '#3399CC',
                    width: borderWidth ? borderWidth : 1.25
                }),
                fill: new Fill({
                    color: fillColor ? fillColor : 'rgba(255,255,255,0.4)'

                }),
                image: new Circle({
                    fill: new Fill({
                        color: fillColor ? fillColor : 'red',
                    }),
                    radius: radius ? radius : 5,
                }),
            })
        })
        id && WFS.set('id', id)
        olMap.map.addLayer(WFS)
        return WFS
    }

    render() {
        return <></>
    }
}
