import React, { Component } from 'react'
import olMap from "../../map";
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import { Style, Stroke } from 'ol/style'
/**
 * @作者:zl
 * @组件说明:在地图上添加线
 * @组件:CLine
 * @param:
 * position   //添加线的坐标数组    格式[[],[]]
 * color      //线的宽度            默认蓝色
 * width      //线的宽度            默认3像素
 * opacity    //透明度              默认1
 * zIndex     //线所在图层的层级    默认1
 * id        //线所在图层的id
 */
interface CLineProps {
    position: number[][] //坐标数组    
    color?: any//线的颜色       
    width?: number //线的宽度    
    opacity?: number//透明度     
    zIndex?: number//线的层级   
    id?: any
}
export default class CLine extends Component<CLineProps> {

    componentDidMount() {
        this.addLine(this.props)
        console.log(this.props.position);

    }
    addLine = ({ position, color, width, opacity, zIndex, id }: lineProps) => {
        const style = new Style({
            stroke: new Stroke({
                color: color ? color : '#4C99F8',
                width: width ? width : 3,
            })
        })
        const features = new Feature({
            geometry: new LineString(position)
        })
        features.setStyle(style)
        const layer = new VectorLayer({
            opacity: opacity ? opacity : 1,
            zIndex: zIndex ? zIndex : 1,
            source: new VectorSource({
                features: [features]
            }),
            style
        })
        id && layer.set('id', id)
        olMap.map.addLayer(layer)
    }
    render() {
        return (
            <></>
        )
    }
}