import 'ol/ol.css';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
// import XYZ from 'ol/source/XYZ'
import View from 'ol/View';
import { defaults as defaultControls } from 'ol/control'
import { defaults } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorLayer } from 'ol/layer'
import { TileWMS, Vector as VectorSource, WMTS, XYZ } from 'ol/source'
import "./lib/config/env.js"
import { unByKey } from 'ol/Observable';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
// import BaseLayer from 'ol/layer/Base';
const env = (window as any)["ResourceURL"]
export default class olMap {
    static layerParams = {
        //云图黑
        black: {
            "name": "云图(黑)",
            "type": "YUNTUHEI",
            "zIndex": [9, 9],
            "url": [env.wmsLocal],
            "format": "image/png",
            "style": "",
            "matrixSet": "GoogleCRS84Quad",
            "layer": ["cloud_map:ls_map_grey"],
            "resolutions": [
                1.4062499999999998, 0.7031249999999999,
                0.3515624999999999, 0.17578124999999997,
                0.08789062499999999, 0.04394531249999999,
                0.021972656249999997, 0.010986328124999997,
                0.0054931640625, 0.0027465820312499987,
                0.0013732910156249998, 6.866455078124998E-4,
                3.4332275390625E-4, 1.7166137695312497E-4,
                8.583068847656249E-5, 4.291534423828124E-5,
                2.145767211914062E-5, 1.072883605957031E-5,
                5.364418029785155E-6
            ],
            "matrixIds": [
                "GoogleCRS84Quad:0", "GoogleCRS84Quad:1",
                "GoogleCRS84Quad:2", "GoogleCRS84Quad:3",
                "GoogleCRS84Quad:4", "GoogleCRS84Quad:5",
                "GoogleCRS84Quad:6", "GoogleCRS84Quad:7",
                "GoogleCRS84Quad:8", "GoogleCRS84Quad:9",
                "GoogleCRS84Quad:10", "GoogleCRS84Quad:11",
                "GoogleCRS84Quad:12", "GoogleCRS84Quad:13",
                "GoogleCRS84Quad:14", "GoogleCRS84Quad:15",
                "GoogleCRS84Quad:16", "GoogleCRS84Quad:17",
                "GoogleCRS84Quad:18"],
        },
        //云图白
        white: {
            "name": "云图(白)",
            "type": "YUNTUBAI",
            "zIndex": [9, 9],
            "url": [env.wmsLocal],
            "format": "image/png",
            "style": "",
            "matrixSet": "GoogleCRS84Quad",
            "layer": ["cloud_map:ls_map_normal"],
            "resolutions": [
                1.4062499999999998, 0.7031249999999999,
                0.3515624999999999, 0.17578124999999997,
                0.08789062499999999, 0.04394531249999999,
                0.021972656249999997, 0.010986328124999997,
                0.0054931640625, 0.0027465820312499987,
                0.0013732910156249998, 6.866455078124998E-4,
                3.4332275390625E-4, 1.7166137695312497E-4,
                8.583068847656249E-5, 4.291534423828124E-5,
                2.145767211914062E-5, 1.072883605957031E-5,
                5.364418029785155E-6
            ],
            "matrixIds": [
                "GoogleCRS84Quad:0", "GoogleCRS84Quad:1",
                "GoogleCRS84Quad:2", "GoogleCRS84Quad:3",
                "GoogleCRS84Quad:4", "GoogleCRS84Quad:5",
                "GoogleCRS84Quad:6", "GoogleCRS84Quad:7",
                "GoogleCRS84Quad:8", "GoogleCRS84Quad:9",
                "GoogleCRS84Quad:10", "GoogleCRS84Quad:11",
                "GoogleCRS84Quad:12", "GoogleCRS84Quad:13",
                "GoogleCRS84Quad:14", "GoogleCRS84Quad:15",
                "GoogleCRS84Quad:16", "GoogleCRS84Quad:17",
                "GoogleCRS84Quad:18"],
        },
        //天地图影像
        tdtmap: {
            "name": "天地图(影像)",
            "type": "TIANDITU",
            "zIndex": [9, 9],
            "url": [
                env.tdt2Dimg,
            ],
            "img": true,
            "format": "tiles",
            "style": "default",
            "matrixSet": "c",
            "layer": ["img", "cva"],
            "resolutions": [
                1.4062499999999998, 0.7031249999999999,
                0.3515624999999999, 0.17578124999999997,
                0.08789062499999999, 0.04394531249999999,
                0.021972656249999997, 0.010986328124999997,
                0.0054931640625, 0.0027465820312499987,
                0.0013732910156249998, 6.866455078124998E-4,
                3.4332275390625E-4, 1.7166137695312497E-4,
                8.583068847656249E-5, 4.291534423828124E-5,
                2.145767211914062E-5, 1.072883605957031E-5,
                5.364418029785155E-6
            ],
            "matrixIds": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        },
        //莲都区
        ldq: {
            "name": "莲都区(局域网)",
            "zIndex": [9],
            "url": [env.ld2Dimg],
            // "url": [CLOUD.ld2Dimg1, CLOUD.ld2Dimg2, CLOUD.ld2Dimg3],
            "extent": [119.712739, 28.358975, 120.038381, 28.517419],
            "img": true,
        },
        //开发区
        kaifaqu: {
            "name": "开发区(局域网)",
            "zIndex": [9, 9, 9, 9, 9, 9],
            "url": [env.ld2Kf1, env.ld2Kf2, env.ld2Kf3, env.ld2Kf4, env.ld2Kf5, env.ld2Kf6],
            "extent": [119.713083, 28.358975, 120.040441, 28.518277],
            "img": true,
        },
        //丽水市
        lss: {
            "name": "丽水市(局域网)",
            "zIndex": [9],
            "url": [env.ls2Dimg],
            "extent": [118.69354248046875, 27.4181001198329, 120.43487548828126, 28.9552822929746],
            "img": true,
        },
        //浙江省
        zhejiang: {
            "name": "浙江省(局域网)",
            "zIndex": [9],
            "url": [env.zj2Dimg],
            "extent": [118.01513671875003, 27.039556602163188, 122.95898437500003, 31.18460913574326],
            "img": true,
        },
        //全国
        national: {
            "name": "全国(局域网)",
            "zIndex": [8],
            "extent": [73.47656250000001, 3.513421045640058, 135.17578125, 53.64463782485652],
            "url": [env.zg2Dimg],
            "img": true,
        },
        //天地图矢量
        tdtsl: {
            "name": "天地图(矢量)",
            "type": "TIANDITU",
            "zIndex": [2, 3],
            "url": [
                env.tdt2Dvec,
            ],
            "format": "tiles",
            "style": "default",
            "matrixSet": "c",
            "layer": ["vec", "cva"],
            "resolutions": [
                1.4062499999999998, 0.7031249999999999,
                0.3515624999999999, 0.17578124999999997,
                0.08789062499999999, 0.04394531249999999,
                0.021972656249999997, 0.010986328124999997,
                0.0054931640625, 0.0027465820312499987,
                0.0013732910156249998, 6.866455078124998E-4,
                3.4332275390625E-4, 1.7166137695312497E-4,
                8.583068847656249E-5, 4.291534423828124E-5,
                2.145767211914062E-5, 1.072883605957031E-5,
                5.364418029785155E-6
            ],
            "matrixIds": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        }
    }

    static map: any;
    // 标注物图层
    static markerLayer: any;
    //鼠标事件回调的参数
    static mouseE: any = []
    static layers = {
        singlePointLayer: new VectorLayer({
            source: new VectorSource()
        })
    }


    //初始化地图
    static init({ id, layer, CenterLonLat, Zoom, maxZoom, projection, extent }: initProps) {
        this.map = new Map({
            layers: layer ? [...layer] : [new TileLayer({ source: new OSM() })],
            target: id,
            interactions: defaults({
                "altShiftDragRotate": true, // 按住shift和Alt，拖动鼠标左键进行地图旋转
            }),
            view: new View({
                center: CenterLonLat ? CenterLonLat : [119.9133, 28.4233],
                zoom: Zoom ? Zoom : 11,
                projection: projection ? projection : "EPSG:4326",
                maxZoom: maxZoom ? maxZoom : 18,
                extent: extent ? extent : [-180, -90, 180, 90]
            }),
            controls: defaultControls({
                zoom: false,
                rotate: false,
                attribution: false,
            })
        });
        this.layers.singlePointLayer.set('id', 'singlePoint')
        this.markerLayer = new VectorLayer({
            zIndex: 99,
            source: new VectorSource({
                features: []
            })
        })
        this.markerLayer.set('id', 'marker-layer')
        this.map.addLayer(this.markerLayer)
        this.map.addLayer(this.layers.singlePointLayer)
    }


    static getLayer(id: any) {
        return this.map.getLayers().array_.find((layer: any) => !!layer.get('id') && id == layer.get('id'));
    }

    static getEvent(EventId: any) {
        return this.mouseE.find((item: any) => item && item.id === EventId)
    }

    //新增与移除地图交互
    static addInteraction(interaction: any) {
        this.map.addLayer(interaction.layer)
        this.map.addInteraction(interaction.draw)
    }
    static removeInteraction(interaction: any) {
        this.map.removeLayer(interaction.layer)
        this.map.removeInteraction(interaction.draw)
    }


    //新增地图鼠标事件
    static addEvent(id: any, type: any, event: any) {
        this.mouseE.push({
            id: id,
            object: this.map.on(type, (e: any) => event(e))
        })
    }

    //移除地图鼠标事件
    static removeEvent(id: any) {
        this.mouseE && this.mouseE.map((item: any, index: any) => {
            if (id === item.id) {
                unByKey(item.object)
                this.mouseE.splice(index, 1)
            }
        })

    }

    //地图跳转移动事件
    static mapMove(center = this.map.getView().getCenter(), zoom = this.map.getView().getZoom(), { rotation, duration, resolution }: any = { rotation: undefined, duration: 500, resolution: undefined }) {
        if (typeof center === 'number' && !isNaN(center)) {
            zoom = center
            center = this.map.getView().getCenter()
        }
        this.map.getView().animate({
            center,
            zoom,
            rotation, // 缩放完成view视图旋转弧度
            duration,
            resolution
        })
    }




    //添加wmts图层
    static addTileLayer = (Source: any) => {
        let layers: any[] = [];
        if (!Array.isArray(Source.url)) return layers;
        Source.url.forEach((r: any, i: any) => {
            if (Source.img) {
                layers[i] = new TileLayer({
                    zIndex: 1,
                    source: new XYZ({
                        url: r
                    })
                })
            } else {
                layers[i] = new TileLayer({
                    zIndex: 1,
                    source: new WMTS({
                        url: r || '',
                        layer: Source.layer[i] || '',
                        matrixSet: Source.matrixSet,
                        format: Source.format,
                        style: Source.style,
                        tileGrid: new WMTSTileGrid({
                            // tileSize: [256, 256],
                            // extent: [-180.0, -90.0, 180.0, 90.0],
                            origin: [-180.0, 90.0],
                            resolutions: Source.resolutions,
                            matrixIds: Source.matrixIds,
                        }),
                        wrapX: true
                    })
                })
            }
        });
        return layers
    }
    //添加wms图层
    static addTileWMS = (Source: any) => {

        let layers: any = [];
        if (!Array.isArray(Source.url)) return layers;

        Source.url.forEach((r: any, i: any) => {
            layers[i] = new TileLayer({
                zIndex: Source.zIndex[i] || 2,
                minZoom: Source.Zoom[i][0] || 1,
                maxZoom: Source.Zoom[i][1] || 20,
                source: new TileWMS({
                    url: r,
                    params: {
                        id: Source.id,
                        LAYERS: Source.layers[i],
                        VERSION: Source.version || "1.1.0", //请求服务的版本
                        TRANSPARENT: true, //输出图像背景是否透明
                        STYLES: Source.styles,
                        FORMAT: Source.format,
                        maxZoom: Source.maxZoom,
                        width: Source.width,
                        height: Source.height,
                        VIEWPARAMS: Source.viewparams
                    }
                })
            })
        });
        return layers;
    }
    //添加xyz图层
    static addTileXYZ = (Source: any) => {
        let layers: any = [];
        if (!Array.isArray(Source.url)) return layers;
        Source.url.forEach((r: any, i: any) => {
            layers[i] = new TileLayer({
                zIndex: Source.zIndex[i] || 2,
                source: new XYZ({
                    url: r,
                })
            })
        });
        return layers;
    }



}