import React, { Component } from "react";
import Polygon from "ol/geom/Polygon"
import { Feature } from "ol";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import olMap from "../../map";
import { Fill, Stroke, Style } from "ol/style";
import { Coordinate } from "ol/coordinate";
/**
 * @作者:hyh
 * @组件说明:地图添加多边形
 * @组件:CPolygon
 * @param:
    coordinates       //多边形的坐标数组  格式 [[],[],[],[]]
    borderColor       //添加的多边形的边框颜色
    borderWidth       //多边形边框粗细
    fillColor         //多边形的填充颜色
    data              //添加多个多边形数据源 格式 [ [[],[],[],[]] , [[],[],[],[]] , [[],[],[],[]] ]
 */
interface CPolygonProps {
    coordinates?: number | Coordinate[] //多边形的坐标数组 
    borderColor?: string     //添加的多边形的边框颜色
    borderWidth?: number     //多边形边框粗细
    fillColor?: string        //多边形的填充颜色
    data?: any
    zIndex?: number              //多边形数据源
}

export default class CPolygon extends Component<CPolygonProps> {


    componentDidMount = () => {
        const { coordinates, borderColor, borderWidth, fillColor, data } = this.props

        if (data) {
            this.addMorePolygon({ data, borderColor, borderWidth, fillColor })
        } else {
            this.addPolygon({ coordinates, borderColor, borderWidth, fillColor })
        }

    }

    //添加多边形 
    addPolygon = ({ coordinates, borderColor, borderWidth, fillColor, zIndex }: polygonProps) => {
        const polygon = new Polygon([coordinates])
        const feature = new Feature({
            geometry: polygon,  //plygon代表多边形，其他的还有point点、cricle圆等，api上有写
        })

        const source = new Vector({
            features: [feature]

        })
        const layer = new VectorLayer({
            zIndex: zIndex ? zIndex : 1,
            source: source,
            style: new Style({
                stroke: new Stroke({
                    color: borderColor ? borderColor : "#3399CC",
                    width: borderWidth ? borderWidth : 2
                }),
                fill: new Fill({
                    color: fillColor ? fillColor : "rgba(255,255,255,0.5)"
                }),
            })
        })
        olMap.map.addLayer(layer)
    }

    //添加多个多边形
    addMorePolygon = ({ data, borderColor, borderWidth, fillColor, zIndex }: polygonProps) => {
        const features = data.map((item: any) => {
            return new Feature({
                geometry: new Polygon([item]),  //plygon代表多边形，其他的还有point点、cricle圆等，api上有写
            })
        })

        const source = new Vector({
            features: features
        })
        const layer = new VectorLayer({
            zIndex: zIndex ? zIndex : 1,
            source: source,
            style: new Style({
                stroke: new Stroke({
                    color: borderColor ? borderColor : "#3399CC",
                    width: borderWidth ? borderWidth : 2
                }),
                fill: new Fill({
                    color: fillColor ? fillColor : "rgba(255,255,255,0.5)"
                }),
            })
        })
        olMap.map.addLayer(layer)
    }

    render() {
        return <></>
    }
}
