var TDTKEY = '36596ab927247d2891ce4413ea09f0d4'
window['ResourceURL'] = {
    // 路径规划API
    // URL_REACT: 'http://183.245.15.26:8089',//镜像
    URL_REACT: 'http://192.168.1.144:8088',//本地
    // URL_REACT: 'http://47.96.133.22:8062',//线上
    wms_luwang: 'http://47.96.112.120:9000/geoserver/test/wms',
    // 本地测试
    wmsLocal: 'http://47.96.112.120:9000/geoserver/gwc/service/wmts',
    // g高德地名搜索API
    textApi: 'https://restapi.amap.com/v3/place/text?key=e54311ee8a783d90f3abf679a43c0af4&keywords=',
    inputtipsApi: 'https://restapi.amap.com/v3/assistant/inputtips?key=e54311ee8a783d90f3abf679a43c0af4&keywords=',
    //天地图地名搜索API
    tdtInputApi: 'http://api.tianditu.gov.cn/v2/search?tk=442feb8af5ef7a330685bbf07576bbad&postStr=',
    // 倾斜摄影模型
    addressModel: 'https://tiledata.oss-cn-hangzhou.aliyuncs.com/202104kaifaqu/qukuai2/qukuai2m3dtiles.json',
    addressModel1: 'https://tiledata.oss-cn-hangzhou.aliyuncs.com/202104kaifaqu/qukuai3/qukuai3m3dtiles.json',
    // 天地图--三维影像
    tdt3Dimg: 'http://t{s}.tianditu.com/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=693ce7ba0f382a16280129ec9739f828',
    tdt3Dcva: 'http://t{s}.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=693ce7ba0f382a16280129ec9739f828',
    // 天地图--三维影像
    tdt2Dimg: `http://t{0-7}.tianditu.com/DataServer?T=img_w&tk=${TDTKEY}&x={x}&y={y}&l={z}`,
    tdt2Dcva: 'http://t0.tianditu.com/cva_c/wmts?tk=' + TDTKEY,
    tdt2Dvec: 'https://t0.tianditu.gov.cn/vec_c/wmts?tk=' + TDTKEY,
    ld2Dimg: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102/{z}/{x}/{y}.png',

    ld2Kf1: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102-1/{z}/{x}/{y}.png',
    ld2Kf2: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102-2/{z}/{x}/{y}.png',
    ld2Kf3: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102-3/{z}/{x}/{y}.png',
    ld2Kf4: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102-4/{z}/{x}/{y}.png',
    ld2Kf5: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102-5/{z}/{x}/{y}.png',
    ld2Kf6: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220411/331102-6/{z}/{x}/{y}.png',
    ls2Dimg: 'https://ls-dom.oss-cn-hangzhou.aliyuncs.com/20220510/ls_satellite/{z}/{x}/{y}.png',
    zj2Dimg: 'https://dctiles.oss-cn-hangzhou.aliyuncs.com/zj/{z}/{x}/{y}.png',
    zg2Dimg: 'https://dctiles.oss-cn-hangzhou.aliyuncs.com/zg/{z}/{x}/{y}.png',
}