import React, { Component } from "react";
import ReactDOM from 'react-dom';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { KML } from 'ol/format';
// import XYZ from 'ol/source/XYZ'
import olMap from "../../map";
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
// import CPoint from "../COverlayer/CPoint";
import "./index.scss"
import { TileWMS, WMTS, XYZ, Vector } from "ol/source";
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { Polygon, MultiPolygon, Point } from 'ol/geom.js';
import { Feature, Overlay, } from 'ol';
import pointsrc from "../icons/point.svg"
import WMTSTileGrid from "ol/tilegrid/WMTS";
import { CDraw } from "../interaction/CDraw";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
/**
    * @作者:hyh
    * @组件说明:地图初始化组件
    * @组件:CMap
    * @param:
            className       类名
            CenterLonLat    中心点坐标
            Zoom            地图层级
            defaultZoom     地图初始化默认层级11
            defaultExtent   地图初始化默认视角范围[-180, -90, 180, 90]
            defaultLayer    地图初始化默认图层openlayers的OSM图层
            extent          地图视角范围
            layer           自定义加载图层
            layerType       图层类型(默认ol地图） 已有类型 CWhite(云图白)  CBlack(云图黑)  TDTYXMap(天地图影像)  LdMap(莲都区)  DevelopMap(开发区)  LsMap(丽水市) ZjMap(浙江省) NationalMap(全国)  TDTSLMap(天地图矢量图)
            maxZoom         地图加载的最大层级
            projection      地图坐标系  默认EPSG4326
    *@method:
            map             map实例对象
            exportKML       静态导出kml方法
            addEvent        添加鼠标事件 （type：事件类型，event：回调）
            removeEvent     移除鼠标事件
            addDraw         添加地图绘画交互
            removeDraw      移除地图绘画交互
            addWMTSLayer    添加WMTS图层
            addXYZLayer     添加xyz图层
            addWMSLayer     添加WMS图层
            addTabs         在地图上添加标签
            mapMove         地图跳转移动
            addVectorLayer  添加矢量图层
            cleanMarker     清空CDraw标注方法
            addOverlay      添加地图弹窗方法
            removeOverlay   移除地图弹窗方法
    */
interface CMapProps {
    id: string;
    className?: string; //类名
    CenterLonLat?: number[];   //中心点坐标
    Zoom?: number     //初始化层级
    defaultZoom?: boolean
    defaultExtent?: boolean
    defaultLayer?: boolean
    extent?: number[]     //视角范围 [ ]
    layer?: any     //自定义加载图层
    layerType?: string; //初始化图层类型
    style?: React.CSSProperties; //样式
    maxZoom?: number  //最大层级
    projection?: string  //坐标系  默认EPSG4326
    children?: any
}
let draw: any
export default class CMap extends Component<CMapProps> {

    state = {
        map: null
    }

    //map实例对象
    static map: any

    componentDidMount = () => {
        const { id, CenterLonLat, Zoom, layerType, maxZoom, projection, layer, defaultZoom, defaultExtent, defaultLayer, extent } = this.props
        if (defaultLayer) {
            olMap.init({ id, layer: null, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
            this.setState({
                map: olMap.map
            })
            CMap.map = olMap.map
        } else {
            if (layer) {
                olMap.init({ id, layer, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                this.setState({
                    map: olMap.map
                })
                CMap.map = olMap.map
            } else {
                if (layerType) {
                    switch (layerType) {
                        //云图白
                        case "CWhite":
                            const layer1 = olMap.addTileLayer(olMap.layerParams.white)
                            olMap.init({ id, layer: layer1, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //云图黑
                        case "CBlack":
                            const layer2 = olMap.addTileLayer(olMap.layerParams.black)
                            olMap.init({ id, layer: layer2, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //天地图影像
                        case "TDTYXMap":
                            const layer3 = olMap.addTileLayer(olMap.layerParams.tdtmap)
                            olMap.init({ id, layer: layer3, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //莲都区
                        case "LdMap":
                            const layer4 = olMap.addTileLayer(olMap.layerParams.ldq)
                            olMap.init({ id, layer: layer4, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //开发区
                        case "DevelopMap":
                            const layer5 = olMap.addTileLayer(olMap.layerParams.kaifaqu)
                            olMap.init({ id, layer: layer5, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //丽水市
                        case "LsMap":
                            const layer6 = olMap.addTileLayer(olMap.layerParams.lss)
                            olMap.init({ id, layer: layer6, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //浙江省
                        case "ZjMap":
                            const layer7 = olMap.addTileLayer(olMap.layerParams.zhejiang)
                            olMap.init({ id, layer: layer7, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //全国
                        case "NationalMap":
                            const layer8 = olMap.addTileLayer(olMap.layerParams.national)
                            olMap.init({ id, layer: layer8, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                        //天地图矢量图
                        case "TDTSLMap":
                            const layer9 = olMap.addTileLayer(olMap.layerParams.tdtsl)
                            olMap.init({ id, layer: layer9, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                            this.setState({
                                map: olMap.map
                            })
                            CMap.map = olMap.map
                            break;
                    }
                } else {
                    olMap.init({ id, layer, CenterLonLat, Zoom: defaultZoom ? undefined : Zoom, maxZoom, projection, extent: defaultExtent ? undefined : extent })
                    this.setState({
                        map: olMap.map
                    })
                    CMap.map = olMap.map
                }
            }
        }
    }

    //绑定地图交互
    static addDraw(type: any, event: any) {//type:绑定事件的类型point,
        draw = new CDraw({
            id: '451',
            type: type,
            style: {
                fillColor: 'rgba(255, 255, 255, 0.5)',
                borderColor: 'red'
            }
        })

        draw.on('drawend', (e: any) => {
            event(e)
        })
        draw.on('drawstart', () => {
            if (type === 'Box') {
                draw.clear()
            }
        })
        olMap.addInteraction(draw)
    }

    //移除地图交互
    static removeDraw() {
        draw && olMap.removeInteraction(draw)
    }

    //绑定地图鼠标事件
    static addEvent(id: any, type: any, event: any) {
        olMap.addEvent(id, type, event)
    }

    //移除地图鼠标事件
    static removeEvent(id: any) {
        olMap.removeEvent(id)
    }

    //地图移动
    static mapMove(center?: any, zoom?: any, params?: any) {
        olMap.mapMove(center, zoom, params)
    }


    //清空所有标注物
    static cleanMarker() {
        this.removeLayer('marker-layer')
    }

    //获取图层
    static getLayer(id: any) {
        const layer = olMap.getLayer(id)
        return layer
    }

    //移除图层的方法
    static removeLayer(id: any) {
        const layer = olMap.getLayer(id)
        layer && olMap.map.removeLayer(layer)
    }

    //添加标签
    static addTabs(
        { id, data, type, offsetX, offsetY, src, imgSize, iconOffset, iconScale, minZoom, maxZoom, zIndex, opacity, fontSzie }:
            {
                id: any, data: any, type: any, offsetX?: number, offsetY?: any, src?: any, imgSize?: any, iconOffset?: any, iconScale?: any,
                minZoom?: any, maxZoom?: any, zIndex?: any, opacity?: any, fontSzie?: number
            }
    ) {
        const list = data.filter((item: any) => {
            return item.LonLat
        })
        const newlist = list.map((item: any) => {
            return new Feature({
                geometry: new Point(item.LonLat),
                data: { ...item }
            })
        })
        if (type === 'Tab') {
            newlist.map((item: any) => {
                item.setStyle(new Style({
                    image: new CircleStyle({
                        fill: new Fill({
                            color: 'transparent',
                        }),
                        radius: 7,
                    }),
                    text: new Text({
                        text: item.values_.data.tab,
                        fill: new Fill({
                            color: item.values_.data.color ? item.values_.data.color : '#fff',
                        }),
                        font: fontSzie ? `${fontSzie}px sans-serif` : undefined,
                        offsetX: offsetX ? offsetX : 0,
                        offsetY: offsetY ? offsetY : 0
                    })
                }))
            })
        } else if (type === "iconTab") {
            newlist.map((item: any) => {
                item.setStyle(new Style({
                    image: new Icon({
                        src: src ? src : pointsrc,
                        imgSize: imgSize ? imgSize : [50, 50],
                        offset: iconOffset ? iconOffset : [0, 0],
                        scale: iconScale ? iconScale : 1,
                    }),
                    text: new Text({
                        text: item.values_.data.tab,
                        fill: new Fill({
                            color: item.values_.data.color ? item.values_.data.color : '#fff',
                        }),
                        font: fontSzie ? `${fontSzie}px sans-serif` : undefined,
                        offsetX: offsetX ? offsetX : 0,
                        offsetY: offsetY ? offsetY : 0
                    })
                }))
            })
        }

        let tabsSource = new VectorSource({
            features: newlist
        })
        const tabsLayer = new VectorLayer({
            minZoom: minZoom ? minZoom : undefined,
            zIndex: zIndex ? zIndex : 2,
            maxZoom: maxZoom ? maxZoom : undefined,
            opacity: opacity ? opacity : 1,
            source: tabsSource,
        })
        tabsLayer.set("id", id)
        olMap.map.addLayer(tabsLayer)
        return tabsLayer

    }

    //添加覆盖物
    static addOverlay({ id, lonlat, position, stopEvent, offset, InfoWin, time }:
        { id: any, lonlat: any, InfoWin: any, position?: any, stopEvent?: boolean, offset?: any, time?: any }) {
        const info = document.createElement('div')
        const infoWindow = new Overlay({
            id: id,
            element: info,
            positioning: position ? position : "bottom-center",       //相对于其位置属性的实际位置
            stopEvent: stopEvent,                   //事件冒泡
            offset: offset ? offset : [0, 0]
        })
        // const element = React.createElement(InfoWin, { data: data });
        // const root = ReactDOM.render(
        //     info
        // )
        // root.render(element);
        ReactDOM.render(InfoWin, info);
        infoWindow.setPosition(lonlat)
        if (time) {
            if (!this.map.getOverlayById(id)) {
                this.map.addOverlay(infoWindow)
                setTimeout(() => {
                    this.map.removeOverlay(infoWindow)
                }, time)
            }
        } else {
            if (!this.map.getOverlayById(id)) {
                this.map.addOverlay(infoWindow)
            }
        }
        return infoWindow
    }

    //移除覆盖物
    static removeOverlay(id: any) {
        const infowindow = this.map.getOverlayById(id)
        infowindow && this.map.removeOverlay(infowindow)
    }

    static getEvent(id: any) {
        const event = olMap.getEvent(id)
        return event
    }

    //kml输出方法
    static exportKML(id: any) {
        return new Promise((resolve, reject) => {
            let url;
            setTimeout(() => {
                const layer: any = olMap.getLayer(id)
                if (layer) {
                    const featureList = layer.getSource().getFeatures()
                    const kmlXML = new KML().writeFeaturesNode(featureList)
                    url = new XMLSerializer().serializeToString(kmlXML)
                    // url = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(b)
                    let blob = new Blob([url], { type: "charset=UTF-8" });
                    url = blob
                    // const link = document.createElement("a")
                    // link.href = url;
                    // link.download = `${id}.kml`
                    // link.click()
                    resolve(url)
                } else {
                    console.error(('该图层不存在'));
                    url = null
                    reject(url)
                }
            }, 500);
        })
    }


    //静态添加矢量图层
    static addVectorLayer(
        { id, geometryList, zIndex, opacity, style, maxZoom, minZoom, extent }:
            { id: any, geometryList: any, zIndex?: number, opacity?: number, style?: any, maxZoom?: number, minZoom?: number, extent?: any }) {
        let features: any[] = []
        const fill = new Fill({
            color: style && style.fillColor ? style.fillColor : 'rgba(255,255,255,0.5)',
        });
        const stroke = new Stroke({
            color: style && style.strokeColor ? style.strokeColor : '#4C99F8',
            width: style && style.width ? style.width : 3
        });
        const theStyle = new Style({
            image: new CircleStyle({
                fill,
                stroke,
                radius: style && style.radius ? style.radius : 3
            }),
            fill,
            stroke,
        })
        geometryList.forEach((item: any) => {
            let obj = { ...item }
            delete obj.coordinates
            const geo = new Feature({
                geometry: item.type === 'Polygon' ? new Polygon(item.coordinates) : item.type === 'MultiPolygon' ? new MultiPolygon(item.coordinates) : new Point(item.coordinates),
                data: {
                    ...obj
                }
            })
            geo.setStyle(theStyle)
            features.push(geo)
        })
        const vectorLayer = new VectorLayer({
            opacity: opacity ? opacity : 1,
            zIndex: zIndex ? zIndex : 2,
            maxZoom: maxZoom ? maxZoom : undefined,
            minZoom: minZoom ? minZoom : undefined,
            extent: extent,
            source: new Vector({
                features
            })
        })
        vectorLayer.set("id", id)
        olMap.map.addLayer(vectorLayer)
        return vectorLayer
    }

    //添加海量点图层
    static addMasspointLayer({ size, color, data, id, zIndex }: { size?: any, color?: any, data: any, id: any, zIndex?: any }) {
        let style = {
            symbol: {
                symbolType: 'circle',
                size: size ? size : 10,
                color: color ? color : '#ff0000'
            }
        };
        let newFeatures: any = [];
        data.forEach((item: any, index: any): any => {
            const feature = new Feature({
                geometry: new Point([item.x, item.y]),
                data: item
            })
            newFeatures.push(feature)
            if (index === data.length - 1) {
                let vectorSource = new VectorSource({
                    features: newFeatures,

                });
                let layer = new WebGLPointsLayer({
                    zIndex: zIndex ? zIndex : 2,
                    source: (vectorSource as any),
                    style: style
                })
                layer.set("id", id)
                olMap.map.addLayer(layer);
                return layer
            }
        })
    }

    //静态添加wms图层
    static addWMSLayer(
        { id, url, zIndex, opacity, params, maxZoom, minZoom, extent }:
            { id: any, url: any, zIndex?: number, opacity?: number, params?: any, maxZoom?: number, minZoom?: number, extent?: any }
    ) {
        const WMSLayer = new TileLayer({
            opacity: opacity ? opacity : 1,
            zIndex: zIndex ? zIndex : 2,
            maxZoom: maxZoom ? maxZoom : undefined,
            minZoom: minZoom ? minZoom : undefined,
            extent: extent,
            source: new TileWMS({
                url: url,
                params: params ? params : ""
            })
        })
        WMSLayer.set("id", id)
        // olMap.map.refresh()
        olMap.map.addLayer(WMSLayer)
        return WMSLayer
    }

    //静态添加XYZ图层
    static addXYZLayer(
        { id, url, zIndex, opacity, maxZoom, minZoom, extent }:
            { id: any, url: any, zIndex?: number, opacity?: number, maxZoom?: number, minZoom?: number, extent?: any }
    ) {
        const XYZLayer = new TileLayer({
            zIndex: zIndex ? zIndex : 2,
            opacity: opacity ? opacity : 1,
            maxZoom: maxZoom ? maxZoom : undefined,
            minZoom: minZoom ? minZoom : undefined,
            extent: extent,
            source: new XYZ({
                url: url,
            })
        })

        XYZLayer.set("id", id)
        olMap.map.addLayer(XYZLayer)
        return XYZLayer
    }

    //静态添加wmts图层
    static addWMTSLayer(
        { id, url, zIndex, matrixSet, opacity, layer, maxZoom, minZoom, format, style, resolutions, matrixIds, extent }:
            {
                id: any, url: any, matrixSet: any, zIndex?: number, opacity?: number, layer?: any, maxZoom?: number, minZoom?: number, format: any,
                style: any, resolutions: any, matrixIds: any, extent?: any
            }
    ) {
        const WMTSLayer = new TileLayer({
            zIndex: zIndex ? zIndex : 2,
            opacity: opacity ? opacity : 1,
            maxZoom: maxZoom ? maxZoom : undefined,
            minZoom: minZoom ? minZoom : undefined,
            extent: extent,
            source: new WMTS({
                url: url,
                layer: layer || '',
                matrixSet: matrixSet,
                format: format ? format : undefined,
                style: style ? style : "",
                tileGrid: new WMTSTileGrid({
                    // tileSize: [256, 256],
                    // extent: [-180.0, -90.0, 180.0, 90.0],
                    origin: [-180.0, 90.0],
                    resolutions: resolutions,
                    matrixIds: matrixIds,
                }),
                wrapX: true
            })
        })
        WMTSLayer.set("id", id)
        WMTSLayer && olMap.map.addLayer(WMTSLayer)
        return WMTSLayer
    }

    render() {
        const { id } = this.props
        const { map } = this.state
        return <div id={id}  >
            {map && this.props.children}
        </div>
    }
}
